import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Intro from "../components/defaultTemplate/Intro"

const DefaultPage = props => {
  const { seoInfo } = props.data
  const intro = props?.data?.intro?.template?.defaultPage
  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Intro data={intro} />
    </Layout>
  )
}

export const defPageQuery = graphql`
  query defPagePage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    intro: wpPage(id: { eq: $id }) {
      template {
        ... on WpDefaultTemplate {
          defaultPage {
            introTitle
            introBigContent
            introMainContent
          }
        }
      }
    }
  }
`

export default DefaultPage
